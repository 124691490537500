<template>
  <button
    class="store-button"
    :class="buttonClass"
    @click="handleClick(version)"
  >
    <img v-if="buttonText" class="button-text" :src="buttonText" />
  </button>
</template>

<script>
export default {
  name: "StoreButton",
  props: {
    version: {
      type: String,
      required: false,
      default: "apple",
      validator: (val) => {
        return ["apple", "google"].includes(val);
      },
    },
  },
  components: {},
  data: () => ({}),
  created() {},
  computed: {
    buttonText() {
      // TODO: handle multi language
      if(this.version == "apple") {
        return `/images/stores/${this.$i18n.locale}_apple-button-text.svg`;
      } else if((this.version = "google")) {
        return `/images/stores/${this.$i18n.locale}_google-button-text.svg`;
      }

      return undefined;
    },
    buttonClass() {
      switch (this.version) {
        case "apple":
          return this.$i18n.locale == "en" ? "apple-button apple-button_en" : "apple-button";
        case "google":
          return "google-button";
        default:
          return "";
      }
    },
  },
  methods: {
    handleClick(version) {
      switch (version) {
        case "apple":
          window.open("https://apps.apple.com/hu/app/blockben/id1462882806", "_blank");
          break;
        case "google":
          window.open("https://play.google.com/store/apps/details?id=com.blockben.pay", "_blank");
          break;
        default:
          break;
      }
    },
  },
};
</script>
 
<style lang='scss' scoped>
button {
  border-radius: 5px;
  background-color: #010101;
  height: 45px;
  display: flex;
  align-items: center;
  border: 1px solid #a6a5a5;
  cursor: pointer;

  .button-text {
    height: 100%;
    width: auto;
    max-height: 30px;
  }
}

.apple-button {
  padding: 0 7px 0 14px;

  &_en {
    padding: 0 14px;
  }
}

.google-button {
  padding: 0 12px 0 15px;
}
</style>